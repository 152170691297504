@import "../_config/variables";

.color-facebook, .color-google {
    color: $white;

    &.color-facebook {
        background-color: $color-facebook;
        border-color: $color-facebook;
        box-shadow: 0 1px 3px hsla(0, 0%, 0%, .2);
    }

    &.color-google {
        background-color: $color-google;
        border-color: $color-google;
        box-shadow: 0 1px 3px hsla(0, 0%, 0%, .2);
    }

    &:focus,
    &:hover,
    &:active {
        background-color: $egg-shell;
        box-shadow: none;

        &.color-facebook {
            color: $color-facebook;
        }

        &.color-google {
            color: $color-google;
        }
    }
}